<template>
	<v-container v-if="$can('create', 'Faq') || $can('update', 'Faq')" fluid>
		<v-card v-if="!loading">
			<v-card-title class="pt-6 pb-0 px-6">
				<span class="headline font-weight-light mb-5">Add FAQ</span>
			</v-card-title>

			<v-card-text class="px-6">
				<v-row no-gutters>
					<v-col cols="12" class="mb-5">
						<p class="ma-0 label">Question*:</p>
						<v-text-field
							v-model="question"
							placeholder="Enter Question"
							@input="$v.question.$touch()"
							@blur="$v.question.$touch()"
							:error-messages="questionErrors()"
						></v-text-field>
					</v-col>
					<v-col cols="12">
						<p class="ma-0 label">Services:</p>
						<Search
							:initialData="services"
							:url="`/${$store.state.app.projectId}/services/search`"
							@search-result="updateService($event)"
						></Search>
					</v-col>
					<v-col cols="12">
						<p class="ma-0 label mb-5">Answer*:</p>
						<div id="ckcontent">
							<Editor
								:purpose="`create faq`"
								@changed="editorChanged"
								:initData="answer"
							></Editor>
							<span
								class="error-msg"
								v-for="(error, ind) in answerErrors()"
								:key="ind"
							>
								{{ error }}
							</span>
						</div>
					</v-col>
				</v-row>
			</v-card-text>

			<v-divider></v-divider>

			<v-card-actions class="pa-6 card-actions">
				<UnoSaveButton
					@click.native="saveData()"
					@primary-button-click="faqListing()"
					primary-button-text="Move To Faq Listing"
					secondary-button-text="Continue Editing"
					:alert="alert"
					:saving="isSaving"
				></UnoSaveButton>

				<UnoPublishButton
					v-if="isEdit && $can('publish', 'Faq')"
					@click.native="toggleStatus()"
					domain="faq"
					:id="id"
					:isPublished="isPublished"
				></UnoPublishButton>

				<v-spacer></v-spacer>

				<UnoDeleteButton
					v-if="isEdit && $can('delete', 'Faq')"
					@confirm="deleteFaq()"
				></UnoDeleteButton>
			</v-card-actions>
		</v-card>
	</v-container>
</template>

<script>
import Search from '@/components/Search'

import { required, minLength } from 'vuelidate/lib/validators'
// ACTION BUTTONS
import UnoPublishButton from '@/components/Buttons/UnoPublishButton'
import UnoSaveButton from '@/components/Buttons/UnoSaveButton'
import UnoDeleteButton from '@/components/Buttons/UnoDeleteButton'

import Editor from '@/components/CkEditor'

export default {
	components: {
		Editor,
		Search,
		UnoSaveButton,
		UnoDeleteButton,
		UnoPublishButton,
	},
	props: {
		id: {
			type: String,
			default: null,
		},
	},
	data() {
		return {
			isSaving: false,
			loading: true,
			faqId: null,
			isPublished: true,
			isEdit: false,
			question: '',
			answer: '',
			services: [],
			errors: {
				question: '',
				answer: '',
			},
			alert: {
				show: null,
				message: null,
				error: false,
			},
		}
	},
	validations: {
		question: {
			required,
			minLength: minLength(10),
		},
		answer: {
			required,
			minLength: minLength(17),
		},
	},
	watch: {
		question() {
			this.errors.question = ''
		},
		answer() {
			this.errors.answer = ''
		},
	},
	created() {
		if (this.id) {
			this.isEdit = true
			this.faqId = this.id
			this.getFaq()
		} else {
			this.loading = false
		}
	},
	methods: {
		questionErrors() {
			const errors = []
			if (!this.$v.question.$dirty) return errors

			!this.$v.question.minLength &&
				errors.push('Question must be atleast 10 letters long')
			!this.$v.question.required && errors.push('Question is required.')

			return errors
		},
		answerErrors() {
			const errors = []
			if (!this.$v.answer.$dirty) return errors

			!this.$v.answer.minLength &&
				errors.push('Answer must be atleast 10 letters long')
			!this.$v.answer.required && errors.push('Answer is required.')

			return errors
		},
		editorChanged(data) {
			this.$v.answer.$touch()
			this.answer = data
		},
		setFaqData(data) {
			this.faqId = data.id
			this.services = data.services
			this.question = data.question
			this.answer = data.answer
			this.isPublished = data.isPublished
			this.loading = false
		},
		saveData() {
			this.isSaving = true
			const isEdit = this.isEdit
			const projectId = this.$store.state.app.projectId

			const payload = {
				question: this.question,
				answer: this.answer,
				services: this.services.map((obj) => {
					return obj['id'] ? obj['id'] : obj
				}),
			}

			this.axios({
				method: isEdit ? 'put' : 'post',
				url: isEdit
					? `/${projectId}/faqs/${this.faqId}`
					: `/${projectId}/faqs`,
				data: payload,
			})
				.then((response) => {
					this.isSaving = false

					if (response.status == 200 && response.data.success) {
						const data = response.data.data
						this.isEdit = true
						this.setFaqData(data)
						this.alert.message = response.data.message
					} else {
						//
					}
				})
				.catch((error) => {
					const { data } = error.response
					if (data.statusCode == 400) {
						this.isSaving = false
						this.$snackbar.notify({
							message: data.message,
							color: 'black',
						})
					}
				})
		},
		getFaq() {
			if (this.isEdit) {
				this.isSaving = true

				const projectId = this.$store.state.app.projectId
				this.axios({
					method: 'get',
					url: `/${projectId}/faqs/${this.id}`,
				}).then((response) => {
					let data = response.data.data
					this.setFaqData(data)
					this.isSaving = false
				})
			}
		},
		deleteFaq() {
			this.$store.dispatch('faq/deleteFaq', {
				faqId: this.faqId,
			})
			this.$router.push({
				name: 'faq.list',
			})
		},
		faqListing() {
			this.$router.push({
				name: 'faq.list',
			})
		},
		toggleStatus() {
			const projectId = this.$store.state.app.projectId

			this.axios({
				method: 'post',
				url: `/${projectId}/faqs/toggle-status/${this.faqId}`,
			}).then(() => {
				this.isPublished = !this.isPublished
			})
		},
		updateService(array) {
			this.services = array
		},
	},
}
</script>
<style scoped>

.label {
	font-size: 15px;
	color: black;
}
.headline {
	color: #303f9f !important;
	font-size: 24px !important;
}
.underline {
	border-bottom: 3px solid #8bc34a;
	margin-top: 10px;
	width: 40px;
	display: block;
}
</style>
